const initialState = {
  stockDetail: {},
  // stockDetail:  {
  //   symbol: "INFOSYS.BSE",
  //   symbolName: "INFOSYS CO.LTD.",
  //   open: "259.7500",
  //   high: "260.1500",
  //   low: "258.1000",
  //   price: "300.7500",
  //   volume: "244742",
  //   latestTradingDay: "2023-10-06",
  //   previousClose: "257.7500",
  //   change: "1.0000",
  //   changePercent: "0.3880%",
  //   type: "F&O",
  //   region: "India/Bombay",
  //   marketOpen: "09:15",
  //   marketClose: "15:30",
  //   timeZone: "UTC+5.5",
  //   currency: "INR",
  //   matchScore: "0.5000",
  //   lotSize: "50",
  //   expiryDate: "31/10/2024"
  // },
  orderDetail: {},
  stockList: [
    {
      symbol: "INFOSYS.BSE",
      symbolName: "INFOSYS CO.LTD.",
      open: "259.7500",
      high: "260.1500",
      low: "258.1000",
      price: "300.7500",
      volume: "244742",
      latestTradingDay: "2023-10-06",
      previousClose: "257.7500",
      change: "1.0000",
      changePercent: "0.3880%",
      type: "F&O",
      region: "India/Bombay",
      marketOpen: "09:15",
      marketClose: "15:30",
      timeZone: "UTC+5.5",
      currency: "INR",
      matchScore: "0.5000",
      lotSize: "50",
      expiryDate: "31/10/2024"
    },
    {
      symbol: "IBMO.FRK",
      symbolName: "International Business Machines Corp",
      open: "142.6000",
      high: "143.4150",
      low: "141.7200",
      price: "142.1100",
      volume: "3015784",
      latestTradingDay: "2023-10-10",
      previousClose: "142.2000",
      change: "-0.0900",
      changePercent: "-0.0633%",
      type: "Equity",
      region: "United States",
      marketOpen: "09:30",
      marketClose: "16:00",
      timeZone: "UTC-04",
      currency: "USD",
      matchScore: "1.0000"
    },
    {
      symbol: "TATACHEM.BSE",
      symbolName: "TATA CHEMICALE",
      open: "25.5000",
      high: "25.5100",
      low: "25.4850",
      price: "25.4950",
      volume: "19135",
      latestTradingDay: "2023-10-10",
      previousClose: "25.4450",
      change: "0.0500",
      changePercent: "0.1965%",
      type: "ETF",
      region: "United States",
      marketOpen: "09:30",
      marketClose: "16:00",
      timeZone: "UTC-04",
      currency: "USD",
      matchScore: "0.8571"
    },
    {
      symbol: "TATAPOWER.BSE",
      symbolName: "TATA POWER",
      open: "27.5000",
      high: "27.5100",
      low: "27.4850",
      price: "27.4950",
      volume: "19135",
      latestTradingDay: "2023-10-10",
      previousClose: "v.4450",
      change: "0.0500",
      changePercent: "0.1965%",
      type: "ETF",
      region: "United States",
      marketOpen: "09:30",
      marketClose: "16:00",
      timeZone: "UTC-04",
      currency: "USD",
      matchScore: "0.8571"
    }
  ],
  currentModule: "",
  chartInstance: [],
  dummyData: {
    symbol: "INFOSYS.BSE",
    symbolName: "INFOSYS CO.LTD.",
    open: "259.7500",
    high: "260.1500",
    low: "258.1000",
    price: "300.7500",
    volume: "244742",
    latestTradingDay: "2023-10-06",
    previousClose: "257.7500",
    change: "1.0000",
    changePercent: "0.3880%",
    type: "F&O",
    region: "India/Bombay",
    marketOpen: "09:15",
    marketClose: "15:30",
    timeZone: "UTC+5.5",
    currency: "INR",
    matchScore: "0.5000",
    lotSize: "50",
    expiryDate: "31/10/2024"
  },
  isNotificationRead: false,
  notificationReadId: 0,
  currentUserId: null,
  currentPath: null
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_STOCK_DETAIL_BY_SYMBOLE": {
      return { ...state, stockDetail: action.payload };
    }
    case "SET_STOCK_LIST_BY_SYMBOLE": {
      if (action.payload.symbol) {
        // Find the index of the stock to be updated
        const index = state.stockList.findIndex(
          (stock) => stock.symbol === action.payload.symbol
        );
        // If the stock is found, update it
        if (index !== -1) {
          const updatedStockData = [...state.stockList];
          updatedStockData[index] = action.payload;
          return { stockList: updatedStockData, stockDetail: action.payload };
        } else {
          // If the stock is not found, create a new array by appending the new stock
          const newStockList = [...state.stockList, action.payload];
          return { stockList: newStockList, stockDetail: action.payload };
        }
      }
    }
    case "SET_CURRENT_USER": {
      return { ...state, currentUserId: action.payload };
    }
    case "SET_CURRENT_PATH": {
      return { ...state, currentPath: action.payload };
    }
    // case "SET_STOCK_LIST_BY_SYMBOLE": {
    //   if (action.payload.symbol) {
    //     state.stockList.push(action.payload);
    //   }
    //   return { stockList: state.stockList, stockDetail: action.payload };
    // }
    case "SET_ORDER_DETAIL_BY_ID": {
      return { ...state, orderDetail: action.payload };
    }
    case "SET_CHART_INSTANCE": {
      return { ...state, chartInstance: action.payload };
    }
    case "SET_IS_NOTIFICATION_READ": {
        return { ...state, isNotificationRead: action.payload };
    }
    case "SET_IS_NOTIFICATION_READ_ID": {
      return { ...state, notificationReadId: action.payload };
  }
    case "PURGE_STOCK": {
      return {
        ...state,
        stockDetail: {},
        orderDetail: {},
        stockList: [],
        currentModule: "",
        chartInstance: [],
        dummyData: {},
        isNotificationRead: false,
        notificationReadId: 0,
        currentUserId: null,
        currentPath: null
      };
    }
    default:
      return state;
  }
};

export default stockReducer;
