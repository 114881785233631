// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import stock from './stock'
import wallet from './myWallet'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  stock,
  wallet
})

export default rootReducer
