const initialState = {
  myWallet: {},
};

const myWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MY_WALLET": {
      return { ...state, myWallet: action.payload };
    }
    case "PURGE_MY_WALLET": {
      return { ...state, myWallet: {}};
    }
    default:
      return state;
  }
};

export default myWalletReducer;
