const initialState = {
  userData: {},
  isAuthenticated: false,
  currentUser: { fullName: "", email: "" },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUTH": {
      return { ...state,  currentUser: action.payload,  isAuthenticated: true };
    }
    case "PURGE_AUTH": {
      return { ...state, currentUser: null, isAuthenticated: false,  userData: {}};
    }
    default:
      return state;
  }
};

export default authReducer;
