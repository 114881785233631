export const API_URL=process.env.REACT_APP_API_URL;
export const ADMIN_API_URL=process.env.REACT_APP_ADMIN_API_URL;
export const TOKEN_KEY=process.env.REACT_APP_TOKEN_KEY;
export const APP_NAME="Paper Trading App";
export const AWS_CONFIG={
  ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
  SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME,
  REGION: process.env.REACT_APP_AWS_REGION,
};
export const PUSHER_CONFIG={
  PUSHER_PUBLIC_KEY: process.env.REACT_APP_PUSHER_PUBLIC_KEY,
  PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER
}
export const SOCKET_URL=process.env.REACT_APP_SOCKET_URL;
export const IS_TESTING=process.env.REACT_APP_IS_TESTING;

